
















import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {DataTableColumn, DataTableSearchable} from '@/layouts/back-office/elements/datatable/DataTableMixin';

@Component({})
export default class DataTableSearchBar extends Vue {
  @Prop({type: Array, default: () => []}) protected searchableFields!: DataTableSearchable[];
  @Prop({type: String, default: 'page'}) protected pageKey!: string;
  @Prop({type: String, default: 'search'}) protected searchKey!: string;
  @Prop({type: Number, default: 500}) protected timeout!: number;

  private search: string = '';
  private timeoutCallback?: any;

  private created() {
    if (this.searchKey in this.$route.query) {
      const searchQuery = this.$route.query[this.searchKey];
      if (typeof searchQuery === 'string') {
        this.search = searchQuery;
      }
    }
  }

  get placeholder(): string {
    const searchField = this.searchableFields
      .filter((searchable: DataTableSearchable) => searchable.name)
      .map((searchable: DataTableSearchable) => '-  ' + searchable.name)
      .join('\n');

    return (this.searchableFields.length > 0)
      // tslint:disable-next-line:max-line-length
      ? this.$it('global.search-for', 'You can search for:') + ' \n' + searchField
      : this.$it('global.search', 'Search').toString();
  }

  @Watch('search')
  @Emit('search')
  private onSearchChange(value: string) {
    clearTimeout(this.timeoutCallback);

    this.timeoutCallback = setTimeout(() => {
      const query = Object.assign({}, this.$route.query);
      if (value.length > 0) {
        query[this.searchKey] = value;
      } else {
        delete query[this.searchKey];
      }

      delete query[this.pageKey];

      const currentRoute = this.$router.currentRoute;
      if (currentRoute.query.hasOwnProperty(this.searchKey) && currentRoute.query[this.searchKey] === value) {
        return;
      }
      this.$router.replace({path: this.$route.path, query});

    }, this.timeout);

    return value;
  }
}
