






















import {Component, Emit, Mixins, Model, Watch} from 'vue-property-decorator';
import FilterMixin from '@/layouts/back-office/elements/filters/FilterMixin';

export interface FilterSelectOption {
  key: string;
  icon?: string;
  label: string;
}

@Component({})
export default class SelectFilter extends Mixins(FilterMixin) {
  private selected: string[] = [];
  private selectAllChecked: boolean = false;

  @Watch('value', {immediate: true})
  private valueChange() {
    this.selected = this.value || [];
  }

  @Watch('selected')
  private selectedChanged(selected: string[]) {
    this.value = (selected.length)
      ? selected
      : null;

    this.selectAllChecked = this.options.every((option: FilterSelectOption) => {
      return this.selected.indexOf(option.key) >= 0;
    });
  }

  get options(): FilterSelectOption[] {
    return this.filter.options as FilterSelectOption[];
  }

  private selectAllCheckedChanged() {
    if (this.selectAllChecked && this.selected.length < this.options.length) {
      this.options.forEach((val: any) => {
        this.selected.push(val.key);
      });
    } else {
      this.selected = [];
    }
  }
}
