import {Component, Emit, Model, Prop, Vue, Watch} from 'vue-property-decorator';
// @ts-ignore
import {FilterSelectOption} from '@/layouts/back-office/elements/filters/SelectFilter.vue';
// @ts-ignore
import {FilterDateOptions} from '@/layouts/back-office/elements/filters/DateFilter.vue';
// @ts-ignore
import {FilterBooleanOptions} from '@/layouts/back-office/elements/filters/BooleanFilter.vue';
// @ts-ignore
import {FilterQuerySelectOptions} from '@/layouts/back-office/elements/filters/QuerySelectFilter.vue';

export enum FilterType {
  Select,
  Date,
  Boolean,
  QuerySelect,
}

export interface Filter {
  key: string;
  title: string;
  type: FilterType;
  value?: any;
  options?: FilterSelectOption[] | FilterDateOptions | FilterBooleanOptions | FilterQuerySelectOptions;
  permission?: string;
  default?: any;
}

@Component
export default class FilterMixin extends Vue {
  @Model('change') public model!: any;
  @Prop(Object) protected filter!: Filter;

  protected value: any = this.model;

  @Watch('model', {immediate: true})
  protected modelChange() {
    this.value = this.model;
  }

  @Watch('value', {deep: true})
  @Emit('change')
  protected valueChanged() {
    return this.value;
  }
}
