















import {Component, Mixins, Watch} from 'vue-property-decorator';
import FilterMixin, {FilterType} from '@/layouts/back-office/elements/filters/FilterMixin';
import BooleanFilter from '@/layouts/back-office/elements/filters/BooleanFilter.vue';
import SelectFilter, {FilterSelectOption} from '@/layouts/back-office/elements/filters/SelectFilter.vue';
import DateFilter from '@/layouts/back-office/elements/filters/DateFilter.vue';
import QuerySelectFilter from '@/layouts/back-office/elements/filters/QuerySelectFilter.vue';

@Component({
  components: {QuerySelectFilter, DateFilter, BooleanFilter, SelectFilter},
})
export default class FilterFactory extends Mixins(FilterMixin) {
  private FilterType = FilterType;

}
