















import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import FilterMixin from '@/layouts/back-office/elements/filters/FilterMixin';

export interface FilterBooleanOptions {
  labelYes?: string;
  labelNo?: string;
}

@Component({})
export default class BooleanFilter extends Mixins(FilterMixin) {

  private valueTrue: boolean = false;
  private valueFalse: boolean = false;

  get options(): FilterBooleanOptions {
    return ('options' in this.filter && typeof this.filter === 'object')
      ? this.filter.options as FilterBooleanOptions
      : {};
  }

  @Watch('value', {immediate: true})
  private valueChange() {
    this.valueTrue = this.value === true;
    this.valueFalse = this.value === false;
  }

  @Watch('valueTrue')
  private trueChange(value: boolean) {
    if (value) {
      this.valueFalse = false;
    }
    this.resolveValue();
  }

  @Watch('valueFalse')
  private falseChange(value: boolean) {
    if (value) {
      this.valueTrue = false;
    }
    this.resolveValue();
  }

  private resolveValue() {
    if (this.valueTrue) {
      this.value = true;
    } else if (this.valueFalse) {
      this.value = false;
    } else {
      this.value = null;
    }
  }
}
