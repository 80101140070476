






























import {Component, Emit, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import ActionMenu, {ActionMenuDirection} from '@/layouts/back-office/elements/ActionMenu.vue';
import {Filter, FilterType} from '@/layouts/back-office/elements/filters/FilterMixin';
import FilterFactory from '@/layouts/back-office/elements/filters/FilterFactory.vue';
import {FilterSelectOption} from '@/layouts/back-office/elements/filters/SelectFilter.vue';
import {FilterBooleanOptions} from '@/layouts/back-office/elements/filters/BooleanFilter.vue';
import {FilterQuerySelectOptions} from '@/layouts/back-office/elements/filters/QuerySelectFilter.vue';

@Component({
  components: {FilterFactory, ActionMenu},
})
export default class FilterButton extends Vue {
  @Model('change') public model!: any;
  @Prop(Object) public filter!: Filter;
  @Prop({type: Boolean, default: true}) public clearable!: boolean;

  protected value: any = null;
  private filterDirection: ActionMenuDirection = ActionMenuDirection.BottomRight;

  @Watch('model', {immediate: true})
  protected modelChange() {
    this.value = this.model;
  }

  get isFilled() {
    if (!this.clearable || this.value === null) {
      return false;
    }

    return this.filter.type === FilterType.Boolean || this.value.length > 0 || this.filter.type === FilterType.Date;
  }

  get isDefault() {
    const defaultValue = this.filter.default;
    if (!defaultValue) {
      return this.isFilled;
    }

    switch (this.filter.type) {
      case FilterType.Select:
      case FilterType.QuerySelect:
        return !(this.value !== null && this.value.length === defaultValue.length
          && this.value.every((v: any) => defaultValue.indexOf(v) >= 0)) || this.isFilled;
      case FilterType.Date:
        return this.value.start !== defaultValue.start && this.value.end !== defaultValue.end;
      default:
        return this.value !== defaultValue;
    }
  }

  get readableValue() {
    if (this.value !== null) {
      switch (this.filter.type) {
        case FilterType.QuerySelect:
          if (!this.filter.options) {
            return null;
          }

          const querySelectOptions = this.filter.options as FilterQuerySelectOptions;
          return querySelectOptions.selectedLabels
            ? querySelectOptions.selectedLabels.join(', ')
            : this.value.join(', ');
        case FilterType.Select:
          return this.value.map((value: string) => {
            const options: FilterSelectOption[] = this.filter.options as FilterSelectOption[];
            const option: FilterSelectOption | undefined = options.find((o: FilterSelectOption) => o.key === value);

            return (option) ? option.label : null;
          }).join(', ');
        case FilterType.Boolean:
          const booleanOptions = (this.filter.options || {}) as FilterBooleanOptions;
          return (this.value)
            ? booleanOptions.labelYes || this.$it('global.yes', 'Yes')
            : booleanOptions.labelNo || this.$it('global.no', 'No');
        case FilterType.Date:
          const dates: Date[] = [];
          if (this.value.start) {
            dates.push(this.value.start);
          }
          if (this.value.end) {
            dates.push(this.value.end);
          }
          return dates.map((d) => this.$id(d, 'dateWithoutYear')).join(' - ');
      }
    }
    return null;
  }

  get canClear() {
    if (Array.isArray(this.value)) {
      return this.value.length > 0;
    }
    return this.value !== null;
  }

  private clear() {
    this.value = null;
  }

  @Emit('change')
  @Watch('value')
  private valueChange(value: any) {
    return value;
  }
}
