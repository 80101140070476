





















import {Component, Mixins, Watch} from 'vue-property-decorator';
import FilterMixin from '@/layouts/back-office/elements/filters/FilterMixin';
import InputComboSelect from '@/layouts/back-office/elements/input/InputComboSelect.vue';

export interface FilterQuerySelectOptions {
  query: object;
  dataKey: string;
  clientId?: string;
  selectedLabels?: string[];
}

@Component({
  components: {InputComboSelect},
})
export default class QuerySelectFilter extends Mixins(FilterMixin) {
  private allItems: any[] = [];
  private selected: string[] = [];

  get options(): FilterQuerySelectOptions {
    return this.filter.options! as FilterQuerySelectOptions;
  }

  private onItemReceived(items: any) {
    items.forEach((item: any) => {
      if (this.allItems.findIndex((i: any) => i.id === item.id) < 0) {
        this.allItems.push(item);
      }
    });

    return items;
  }

  @Watch('value', {immediate: true})
  private valueChange() {
    this.selected = this.value || [];
  }

  @Watch('selected')
  private selectedChanged(selected: string[]) {
    const labels = this.allItems
      .filter((item: any) => selected.indexOf(item.id) >= 0)
      .map((item: any) => item.name);

    this.options.selectedLabels = labels;

    this.value = (selected.length)
      ? selected
      : null;
  }
}
