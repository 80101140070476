
























import {Component, Emit, Mixins, Watch} from 'vue-property-decorator';
import FilterMixin from '@/layouts/back-office/elements/filters/FilterMixin';
import {sameDate, toDate12Hour} from '@/plugins/Date';

export interface FixedDate {
  label: string;
  start?: Date;
  end?: Date;
}

export interface FilterDateOptions {
  maxDate?: Date;
  labelStart?: string;
  labelEnd?: string;
  fixedDates?: FixedDate[];
}

interface DateRange {
  start: Date;
  end: Date;
}

@Component({})
export default class DateFilter extends Mixins(FilterMixin) {

  protected value: DateRange | null = null;

  private dates: DateRange | null = null;

  private get now() {
    return new Date();
  }

  private get options(): FilterDateOptions {
    return ('options' in this.filter && typeof this.filter === 'object')
      ? this.filter.options as FilterDateOptions
      : {};
  }

  private isActive(start: Date | null = null, end: Date | null = null) {
    if (this.dates === null || !start || !end) {
      return (this.dates === null && !start && !end);
    }

    return sameDate(this.dates.start, start) && sameDate(this.dates.end, end);
  }

  private select(start: Date | undefined, end: Date | undefined) {
    if (start && end) {
      this.dates = {start: toDate12Hour(start), end: toDate12Hour(end)};
    } else {
      this.dates = null;
    }
  }

  @Watch('value', {immediate: true, deep: true})
  private updateDate(value: DateRange | null) {
    if (value === null && this.dates !== null) {
      this.dates = null;
    } else if (value !== null) {
      if (this.dates === null
        || !sameDate(this.dates.start, value.start) || !sameDate(this.dates.end, value.end)) {

        this.dates = {start: value.start, end: value.end};
      }
    }
  }

  @Watch('dates')
  private datesChanged() {
    if (this.dates !== null) {
      this.value = {start: this.dates.start, end: this.dates.end};
    } else {
      this.value = null;
    }
  }
}
