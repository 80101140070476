





































import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';

@Component({
  components: {Modal},
})
export default class MutationModal extends Vue {
  @Prop(Object) protected mutation!: any;
  @Prop(Object) protected variables!: any;


  public open() {
    const modal = this.$refs.mutationModal as Modal;

    modal.open();
  }

  public close() {
    const modal = this.$refs.mutationModal as Modal;

    modal.close();
  }

  private mutateError(error: string | null) {
    if (error === null) {
      return null;
    }

    return error.toString()
      .replace(new RegExp('Error: ', 'g'), '')
      .replace(new RegExp('GraphQL error:', 'g'), '');
  }
}
